<template>
  <div>
    <b-card class="mt-2">
      <b-row class="mb-2">
        <table class="table">
          <thead>
            <tr>
              <th scope="col" style="font-size: 1.2em;">Grade Levels</th>
            </tr>
          </thead>
        </table>
      </b-row>
      <b-row>
        <b-col cols="6">
          <b-form-group class="mb-40">
            <b-input-group>
              <b-form-input v-model.lazy="levelName" type="text" placeholder="Add Grade Level" :state="levelNameState"></b-form-input>
              <b-input-group-append>
                <b-button @click="addLevel()" variant="warning">Add +</b-button>
              </b-input-group-append>
              <b-form-invalid-feedback id="input-live-feedback">
                Please enter level name
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group class="mb-40">
            <b-input-group>
              <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>
              <b-input-group-append>
                <b-button @click="filter = ''" variant="warning">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table :items="allLevels" :small="true" responsive style="padding-bottom:30px" class="position-relative " :filter="filter" :filter-included-fields="filterOn" @filtered="onFiltered" :fields="column" striped hover :current-page="currentPage" :bordered="true" :per-page="perPage">
        <template v-slot:cell(levelName)="data">
          <b-form-group v-if="editingGradeLId === data.item.id" style="margin-bottom: 0 !important">
            <b-input-group>
              <b-form-input v-model.lazy="data.item.levelName" type="text" placeholder="Update Item" :state="editGradeLevelState(data.item.levelName)"></b-form-input>
              <b-input-group-append>
                <b-button @click="editGradeLevel(data.item)" variant="info">Update</b-button>
              </b-input-group-append>
              <b-input-group-append>
                <b-button @click="editingGradeLevel({ id: 0 })" variant="warning">Cancel</b-button>
              </b-input-group-append>
              <b-form-invalid-feedback id="input-live-feedback">
                Please enter level name
              </b-form-invalid-feedback>
            </b-input-group>
          </b-form-group>
          <span class="font-weight-bold" v-else>
            {{ data.item.levelName }}
          </span>
        </template>
        <template #cell(status)="data">
          <b-badge v-if="data.item.status == 'A'" pill :variant="`light-success`" class="text-capitalize">
            Active
          </b-badge>

          <b-badge v-if="data.item.status == 'P'" pill :variant="`light-danger`" class="text-capitalize">
            Passive
          </b-badge>
        </template>
        <template v-slot:cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="editingGradeLevel(data.item)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Edit Grade Level </span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteGradeLevel(data.item.id)">
              <feather-icon icon="DeleteIcon" />
              <span class="align-middle ml-50">Delete Grade Level</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start" style="padding-left: 0;">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>

          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end" style="padding-right: 5px;">
            <b-pagination first-number last-number :total-rows="totalRows" :per-page="perPage" v-model="currentPage" class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import { BFormGroup, BTable, BRow, BCol, BFormSelect, BPagination, BIcon, BSkeletonIcon, BFormRating } from 'bootstrap-vue';
import settingsStoreModule from '../../settingsStoreModule';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import _ from 'lodash';

import axiosIns from '@/libs/axios';

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BIcon,
    BFormRating,
    BSkeletonIcon,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      edit: null,
      levelName: null,
      editLevelName: '',
      filter: null,
      filterOn: [],
      perPage: 10,
      allLevels: [],
      editingGradeLId: 0,
      currentPage: 1,
      pageOptions: [5, 10, 15, 20],
      totalRows: 0,
      column: [
        {
          key: 'levelName',
          sortable: true,
          label: 'Grade Level Name',
          thClass: 'text-center',
          tdClass: 'text-center pr-0',
        },
        {
          key: 'status',
          sortable: true,
          label: 'Status',
          thClass: 'text-center',
          tdClass: 'text-center pr-0',
        },
        {
          key: 'actions',
          label: 'Actions',
          thClass: 'text-center',
          tdClass: 'text-center pr-0',
        },
      ],
    };
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },
  computed: {
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
    levelNameState() {
      if (this.levelName === null) {
        return null;
      }
      if (this.levelName === '' || this.levelName === ' ' || this.levelName == 0) {
        return false;
      }
    },
  },
  methods: {
    editGradeLevelState(gl) {
      if (gl.levelName === null) {
        return null;
      }
      if (gl === '' || gl === ' ' || gl == 0) {
        return false;
      }
    },
    editingGradeLevel(gl) {
      if (this.editingGradeLId !== gl.id) {
        this.editingGradeLId = gl.id;
      }
    },
    editGradeLevel(gl) {
      if (gl.levelName !== '' || gl.levelName === ' ' || gl.levelName == 0) {
        store
          .dispatch('settings/updateGradeLevelItem', gl)
          .then((res) => {
            this.getGradeLevels();
            this.editLevelName = '';
            this.editingGradeLId = 0;
            this.editToastMessage('Grade Level');
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.editGradeLevelState(gl.levelName);
      }
    },
    getGradeLevels() {
      store
        .dispatch('settings/fetchGradeLevels', [])
        .then((res) => {
          this.allLevels = [];
          res.data.forEach((gl) => {
            this.allLevels.unshift({
              id: gl.id,
              levelName: gl.title,
              status: gl.status,
            });
          });

          this.totalRows = this.allLevels.length;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addLevel() {
      if (this.levelName === '' || this.levelName === ' ' || this.levelName == 0) {
        this.levelNameState;
      } else {
        store
          .dispatch('settings/addNewGradeLevelItem', {
            levelName: _.capitalize(this.levelName.trim()),
          })
          .then((response) => {
            if (response.status == 201) {
              this.getGradeLevels();
              this.toastMessage('New Grade Level');
              this.levelName = null;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    deleteGradeLevel(id) {
      const self = this;
      this.$swal({
        title: 'Are you sure you want to delete this level?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch('settings/deleteGradeLevel', id)
            .then((res) => {
              self.getGradeLevels();
              self.$swal.fire('Deleted!', 'Grade Level has been deleted.', 'success');
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Added Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
    editToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Updated Successful',
          icon: 'CheckIcon',
          variant: 'success',
        },
      });
    },
  },
  created() {
    this.getGradeLevels();
  },
};
</script>
